import { type FC, type MouseEvent, startTransition, useEffect, useState } from 'react';
import styles from './MainHeaderAnimatedInput.module.scss';
import Input from '#components/shared/ui/Input';
import { scrollToTop } from '#utils/scroll.ts';
import SearchIcon from '#components/shared/ui/Icon/Search.tsx';

type Props = {
  placeholder?: string;
};

const MainHeaderAnimatedInput: FC<Props> = ({ placeholder }) => {
  const [active, setActive] = useState<boolean>(false);

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    scrollToTop();
    e.currentTarget.blur();
  };

  useEffect(() => {
    const userViewportHeight = window.innerHeight;

    const onScroll = () => {
      startTransition(() => setActive(window.scrollY >= userViewportHeight));
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Input
      readOnly
      icon={<SearchIcon />}
      onClick={handleClick}
      placeholder={placeholder ?? 'Search by Name, Phone, Email or Address'}
      wrapperClassName={`${styles.container} ${active ? styles.active : ''}`}
    />
  );
};

export default MainHeaderAnimatedInput;
