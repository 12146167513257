import type { FC, SVGAttributes } from 'react';

const SearchIcon: FC<SVGAttributes<SVGElement>> = (props) => {
  const defaults: Partial<SVGAttributes<SVGElement>> = {
    focusable: 'false',
    'aria-hidden': 'true',
    viewBox: '0 0 24 24',
    width: '24px',
    height: '24px',
  };
  const attribs = { ...defaults, ...props };
  return (
    <svg {...attribs}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.8866 17.2537 14.5988 16.0402 15.8576C16.0064 15.8837 15.9738 15.9123 15.9428 15.9433C15.9118 15.9743 15.8833 16.0068 15.8572 16.0406C14.5984 17.2539 12.8863 18 11 18C7.13401 18 4 14.866 4 11ZM16.6174 18.0321C15.0778 19.2636 13.1249 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 13.1251 19.2634 15.0782 18.0317 16.618L21.707 20.2933C22.0975 20.6838 22.0975 21.317 21.707 21.7075C21.3165 22.098 20.6833 22.098 20.2928 21.7075L16.6174 18.0321Z"
      ></path>
    </svg>
  );
};

export default SearchIcon;
